<div class="table-section" *ngIf="!modalLoader">
    <div class="search-field-box">
      <label class="search-label">
        <span class="icon tw-h-8 tw-w-8 tw-p-1" appIcon [data]="searchIcon"></span>
        <input class="search-field" type="text" placeholder="Search" (input)="handleSearch($event,true)">
      </label>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="searching ? copyData: tabledata" class="mat-elevation-z8">
          <ng-container matColumnDef="Name">
              <th class="name-heading" mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element?.userInfo?.firstName || element?.firstName}} </td>
            </ng-container>
            <div class="line"></div>
            <ng-container matColumnDef="EMAIL">
              <th mat-header-cell *matHeaderCellDef> EMAIL </th>
              <td mat-cell *matCellDef="let element"> {{element?.userInfo?.email || element?.email}} </td>
            </ng-container>
          
            <ng-container matColumnDef="READ">
              <th mat-header-cell *matHeaderCellDef> READ </th>
              <td mat-cell *matCellDef="let element"> <label class="read-checkbox-container"><input type="checkbox" [disabled]="isReadDisabled(element)" [checked]="isReadChecked(element)" (change)="handlePermission($event, 'READ', element)"><div class="read-box"></div> </label> </td>
            </ng-container>
  
            <ng-container matColumnDef="WRITE">
              <th mat-header-cell *matHeaderCellDef> WRITE </th>
              <td mat-cell *matCellDef="let element"> <label class="write-checkbox-container"><input type="checkbox" [disabled]="isWriteDisabled(element)" [checked]="isWriteChecked(element)" (change)="handlePermission($event, 'WRITE', element)"><div class="write-box"></div> </label></td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
</div>
<div *ngIf="!modalLoader" class="share-btn-container">
    <app-button-generator [buttonGenInput]="buttonGenInput"></app-button-generator>
</div>
<div class="overlay" *ngIf="modalLoader">
    <div class="overlay-content">
        <div appIcon [data]="spinnerLoader" class="overlay-loader"></div>
    </div>
</div>