import { WidgetHttpService } from './../../../services/http/widget-http/widget-http.service';
import { Widget } from 'src/app/shared/classes/Widget';
import { HttpService } from './../../../services/http/http-main/http.service';
import { NotificationsService } from './../../../services/notifications/notifications.service';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { Component, NgZone, OnInit } from '@angular/core';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IconType } from 'src/app/shared/enums/IconType';
import { Helper } from 'src/app/shared/classes/Helper';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';

@Component({
    selector: 'app-report-share-table',
    templateUrl: './report-share-table.component.html',
    styleUrls: ['./report-share-table.component.sass']
})
export class ReportShareTableComponent implements OnInit {
    public readcheck = false;
    public writecheck = false;
    tableInput: ITableGeneratorInput;
    payload: any[] = [];
    searching: boolean = false;
    searchIcon: IIcon = {
        type: IconType.SVG,
        class: 'search'
    };
    reportShareModelList: any[] = [];
    bulkSharePayload: {} = {};
    buttonRef: IButtonGeneratorInput;
    buttonGenInput: IButtonGeneratorInput = {
        buttonName: 'Share',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        customClass: 'whitelisting-save-btn',
        showLoader: true,
        function: (buttonRef: IButtonGeneratorInput) => {
            if (this.selectedRows && this.selectedRows.length > 0) {
                this.shareReport(buttonRef);
            } else if (
                this.bulkSharePayload &&
                this.bulkSharePayload['reportShareModelList'] &&
                this.bulkSharePayload['reportShareModelList']?.length > 0
            ) {
                this.shareReport(buttonRef);
            } else {
                return Helper.showErrorMessage(
                    this.notificationsService,
                    'Selected atleast one Entry to perform the operation'
                );
            }
        }
    };
    tabledata: any[] = [];
    copyData: any[] = [];
    widgetRef: Widget;
    updatePayload: {} = {};
    modalLoader: boolean = true;
    displayedColumns: string[] = ['Name', 'EMAIL', 'READ', 'WRITE'];
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    selectedRows: any[] = [];
    userDefaultAccess: any[] = [];
    disableRead: boolean = false;
    disableWrite: boolean = false;
    constructor(
        private modalInput: ModalInjectedData,
        public notificationsService: NotificationsService,
        public ngZone: NgZone,
        public httpService: HttpService
    ) {}

    ngOnInit(): void {
        this.getUsersList();
        if (this.modalInput.data?.cardata) {
            this.updatePayload = {
                reportName: this.modalInput.data?.cardata['reportName'],
                widgetIds: this.modalInput.data?.cardata['widgetIds'],
                reportType: this.modalInput.data?.cardata['reportType'],
                widgetFilters: this.modalInput.data?.cardata['widgetFilters'],
                reportFilters: this.modalInput.data?.cardata['reportFilters'],
                userNameWithWidgetsIds: this.modalInput.data?.cardata[
                    'userNameWithWidgetsIds'
                ]
                    ? this.modalInput.data?.cardata['userNameWithWidgetsIds']
                    : null,
                userReportWithAccessControl: this.modalInput.data?.cardata[
                    'userReportWithAccessControl'
                ]
                    ? this.modalInput.data?.cardata[
                          'userReportWithAccessControl'
                      ]
                    : null,
            };
        }
    }

    shareReport(buttonRef: IButtonGeneratorInput) {
        this.buttonRef = buttonRef;
        this.buttonRef.loader = true;

        if (this.modalInput.data?.cardata) {
            const reportId = this.modalInput.data?.cardata?.id;
            const hitApi: IHitApi = Helper.generateHitApiConfig(
                this.modalInput.data['widgetRef']['widgetData']['widgetInfo'][
                    'share'
                ]
            );
            hitApi.url = hitApi.url.replace('{report-id}', reportId);
            hitApi.input = this.selectedRows;
            hitApi.function = (response) => {
                this.buttonRef.loader = false;
                this.modalInput.data.widgetRef.modalService.closeModal(
                    null,
                    this.modalInput.modalId
                );
                if (response?.response === 'false') {
                    const confirmationModal: IConfirmationModal = {
                        modalName: 'Widget Access Control ',
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: ''
                        },
                        contextIcon: {
                            type: IconType.FONTAWSOME,
                            class: ''
                        },
                        buttonText: 'Continue',
                        buttonColorType: ButtonColorType.PRIMARY,
                        modalAutoHeight: true,
                        fontSize: 1,
                        extraClass: 'plan-container',
                        hideCancelButton: false,
                        cancelButtonName: 'Cancel',
                        cancelButtonType: 'PRIMARY',
                        function: (modalId: Symbol) => {
                            const hitApi: IHitApi = Helper.generateHitApiConfig(
                                this.modalInput.data['widgetRef']['widgetData'][
                                    'widgetInfo'
                                ]['widgetPassed']
                            );
                            hitApi.url = hitApi.url.replace(
                                '{report-id}',
                                reportId
                            );
                            hitApi.input = this.selectedRows;
                            hitApi.function = (response) => {
                                this.notificationsService.showSnackBar(
                                    `The 'View Name' view has been created and the report rule successfully shared with the respective users.`,
                                    false
                                );
                                this.hitUpdateApi(reportId);
                            };
                            hitApi.errorFunction = (error) => {
                                this.selectedRows = [];
                                Helper.showErrorMessage(
                                    this.notificationsService,
                                    error
                                );
                                this.modalInput.data.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            };
                            new HitApi(
                                hitApi,
                                this.modalInput.data.widgetRef.httpService,
                                this.modalInput.data.widgetRef.ngZone
                            ).hitApi();
                        },
                        closeCallBack: () => {},
                        confirmationMessage: `Selected users lack access to the 'Report Management' widget. Click 'Continue' to add it to their default view if you own it, or create a new view with the widget if you don't.`
                    };
                    this.modalInput.data.widgetRef.modalService.openConfirmationModal(
                        confirmationModal
                    );
                } else if (response?.response === 'true') {
                    this.notificationsService.showSnackBar(
                        'Report rule successfully shared'
                    );
                    this.hitUpdateApi(reportId);
                }
            };
            hitApi.errorFunction = (error) => {
                this.selectedRows = [];
                Helper.showErrorMessage(this.notificationsService, error);
                this.modalInput.data.widgetRef.modalService.closeModal(
                    null,
                    this.modalInput.modalId
                );
            };
            new HitApi(
                hitApi,
                this.modalInput.data.widgetRef.httpService,
                this.modalInput.data.widgetRef.ngZone
            ).hitApi();
        } else {
            const selectedReportCount =
                this.modalInput.data?.selectedCards.length;
            const hitBulkShare: IHitApi = Helper.generateHitApiConfig(
                this.modalInput.data['widgetRef']['widgetData']['widgetInfo'][
                    'bulkShare'
                ]
            );
            hitBulkShare.input = this.bulkSharePayload;
            hitBulkShare.function = (response) => {
                this.buttonRef.loader = false;
                this.modalInput.data.widgetRef.modalService.closeModal(
                    null,
                    this.modalInput.modalId
                );
                if (response?.response === 'false') {
                    const confirmationModal: IConfirmationModal = {
                        modalName: 'Widget Access Control ',
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: ''
                        },
                        contextIcon: {
                            type: IconType.FONTAWSOME,
                            class: ''
                        },
                        buttonText: 'Continue',
                        buttonColorType: ButtonColorType.PRIMARY,
                        modalAutoHeight: true,
                        fontSize: 1,
                        extraClass: 'plan-container',
                        hideCancelButton: false,
                        cancelButtonName: 'Cancel',
                        cancelButtonType: 'PRIMARY',
                        function: (modalId: Symbol) => {
                            const hitApi: IHitApi = Helper.generateHitApiConfig(
                                this.modalInput.data['widgetRef']['widgetData'][
                                    'widgetInfo'
                                ]['widgetPassedForBulkAction']
                            );
                            hitApi.input = this.bulkSharePayload;
                            hitApi.function = (response) => {
                                this.notificationsService.showSnackBar(
                                    `The 'View Name' view has been created and the report rule successfully shared with the respective users.`
                                );
                                for (let i = 0; i < selectedReportCount; i++) {
                                    const id =
                                        this.modalInput.data?.selectedCards[i][
                                            'id'
                                        ];
                                    const payload = {
                                        reportName:
                                            this.modalInput.data.selectedCards[
                                                i
                                            ]['reportName'],
                                        widgetIds:
                                            this.modalInput.data.selectedCards[
                                                i
                                            ]['widgetIds'],
                                        reportType:
                                            this.modalInput.data.selectedCards[
                                                i
                                            ]['reportType'],
                                        widgetFilters:
                                            this.modalInput.data.selectedCards[
                                                i
                                            ]['widgetFilters'],
                                        reportFilters:
                                            this.modalInput.data.selectedCards[
                                                i
                                            ]['reportFilters'],
                                        userNameWithWidgetsIds: this.modalInput
                                            .data?.selectedCards[i][
                                            'userNameWithWidgetsIds'
                                        ]
                                            ? this.modalInput.data
                                                  .selectedCards[i][
                                                  'userNameWithWidgetsIds'
                                              ]
                                            : null,
                                        userReportWithAccessControl: this
                                            .modalInput.data?.selectedCards[i][
                                            'userReportWithAccessControl'
                                        ]
                                            ? this.modalInput.data
                                                  .selectedCards[i][
                                                  'userReportWithAccessControl'
                                              ]
                                            : null,
                                    };
                                    const emailList =
                                        payload['reportFilters'][
                                            'sendingMechanism'
                                        ]['emailRequest']['copyTo'] || [];
                                    const emailSet = new Set(emailList);
                                    this.bulkSharePayload[
                                        'reportShareModelList'
                                    ]?.forEach((val) => {
                                        const email = val?.userInfo?.email;
                                        if (email && !emailSet.has(email)) {
                                            emailSet.add(email);
                                            emailList.push(email);
                                        }
                                    });
                                    payload['reportFilters'][
                                        'sendingMechanism'
                                    ]['emailRequest']['copyTo'] =
                                        Array.from(emailSet);
                                    this.hitUpdateApi(id, payload);
                                }
                                this.bulkSharePayload = {};
                            };
                            hitApi.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.notificationsService,
                                    error
                                );
                                this.modalInput.data.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                            };
                            new HitApi(
                                hitApi,
                                this.modalInput.data.widgetRef.httpService,
                                this.modalInput.data.widgetRef.ngZone
                            ).hitApi();
                        },
                        closeCallBack: () => {},
                        confirmationMessage: `Selected users lack access to the 'Report Management' widget. Click 'Continue' to add it to their default view if you own it, or create a new view with the widget if you don't.`
                    };
                    this.modalInput.data.widgetRef.modalService.openConfirmationModal(
                        confirmationModal
                    );
                } else if (response?.response === 'true') {
                    for (let i = 0; i < selectedReportCount; i++) {
                        const id = this.modalInput.data?.selectedCards[i]['id'];
                        const payload = {
                            reportName:
                                this.modalInput.data.selectedCards[i][
                                    'reportName'
                                ],
                            widgetIds:
                                this.modalInput.data.selectedCards[i][
                                    'widgetIds'
                                ],
                            reportType:
                                this.modalInput.data.selectedCards[i][
                                    'reportType'
                                ],
                            widgetFilters:
                                this.modalInput.data.selectedCards[i][
                                    'widgetFilters'
                                ],
                            reportFilters:
                                this.modalInput.data.selectedCards[i][
                                    'reportFilters'
                                ],
                            userNameWithWidgetsIds: this.modalInput.data
                                ?.selectedCards[i]['userNameWithWidgetsIds']
                                ? this.modalInput.data.selectedCards[i][
                                      'userNameWithWidgetsIds'
                                  ]
                                : null,
                            userReportWithAccessControl: this.modalInput.data
                                ?.selectedCards[i][
                                'userReportWithAccessControl'
                            ]
                                ? this.modalInput.data.selectedCards[i][
                                      'userReportWithAccessControl'
                                  ]
                                : null,
                        };
                        const emailList =
                            payload['reportFilters']['sendingMechanism'][
                                'emailRequest'
                            ]['copyTo'] || [];
                        const emailSet = new Set(emailList);
                        this.bulkSharePayload['reportShareModelList'].forEach(
                            (val) => {
                                const email = val?.userInfo?.email;
                                if (email && !emailSet.has(email)) {
                                    emailSet.add(email);
                                    emailList.push(email);
                                }
                            }
                        );
                        payload['reportFilters']['sendingMechanism'][
                            'emailRequest'
                        ]['copyTo'] = Array.from(emailSet);
                        this.hitUpdateApi(id, payload);
                    }
                    this.bulkSharePayload = {};
                    this.notificationsService.showSnackBar(
                        'Report rule successfully shared'
                    );
                }
            };
            hitBulkShare.errorFunction = (error) => {
                Helper.showErrorMessage(this.notificationsService, error);
                this.bulkSharePayload = {};
                this.modalInput.data.widgetRef.modalService.closeModal(
                    null,
                    this.modalInput.modalId
                );
            };
            new HitApi(
                hitBulkShare,
                this.modalInput.data.widgetRef.httpService,
                this.modalInput.data.widgetRef.ngZone
            ).hitApi();
        }
    }

    hitUpdateApi(reportId, payload?) {
        if (!payload) {
            if (this.selectedRows.length > 0) {
                const selectedEmailSet = new Set(
                    this.selectedRows.map((val) => val?.userInfo?.email)
                );
                const userDefaultAccessEmailSet = new Set(
                    this.userDefaultAccess.map(
                        (value) => value?.userInfo?.email
                    )
                );
                const currentEmails =
                    this.updatePayload['reportFilters']['sendingMechanism'][
                        'emailRequest'
                    ]['copyTo'] || [];
                selectedEmailSet.forEach((email) => {
                    if (!currentEmails.includes(email)) {
                        currentEmails.push(email);
                    }
                });

                this.updatePayload['reportFilters']['sendingMechanism'][
                    'emailRequest'
                ]['copyTo'] = currentEmails.filter((email) => {
                    return (
                        !userDefaultAccessEmailSet.has(email) ||
                        selectedEmailSet.has(email)
                    );
                });
            }
        }
        const hitApi: IHitApi = Helper.generateHitApiConfig(
            this.modalInput.data['widgetRef']['widgetData']['widgetInfo'][
                'update'
            ]
        );
        hitApi.url = hitApi.url.replace('{report-id}', reportId);
        hitApi.input = payload ? payload : this.updatePayload;
        hitApi.function = (response) => {
            this.modalInput.data?.widgetRef?.refreshWidget();
            if (this.modalInput.data?.cardata) {
                this.selectedRows = [];
                this.updatePayload = {};
                this.userDefaultAccess = [];
            }
        };
        hitApi.errorFunction = (error) => {};
        new HitApi(
            hitApi,
            this.modalInput.data.widgetRef.httpService,
            this.modalInput.data.widgetRef.ngZone
        ).hitApi();
    }
    getUsersList() {
        const hitApi: IHitApi = {
            url: ApiUrls.USERS_END_POINT,
            requestType: RequestType.GET,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },

            function: (response) => {
                this.modalLoader = false;
                if (this.modalInput.data?.cardata) {
                    if (response.length > 0) {
                        response.forEach((value) => {
                            if (
                                this.modalInput.data?.cardata
                                    ?.userReportWithAccessControl
                            ) {
                                this.modalInput.data?.cardata?.userReportWithAccessControl.forEach(
                                    (val) => {
                                        const email = val?.userInfo?.email;
                                        if (value?.email === email) {
                                            const obj = {
                                                reportAccessControlFlag:
                                                    val?.reportAccessControlFlag,
                                                userInfo: val?.userInfo
                                            };
                                            this.selectedRows.push(obj);
                                            this.userDefaultAccess.push(obj);
                                            this.tabledata.push(obj);
                                        }
                                    }
                                );
                                const index = this.tabledata.findIndex(
                                    (item) =>
                                        item?.userInfo?.email === value?.email
                                );
                                if (index === -1) {
                                    const obj = {
                                        reportAccessControlFlag: '',
                                        userInfo: value
                                    };
                                    this.tabledata.push(obj);
                                }
                            } else {
                                this.tabledata = [];
                                response.forEach((value) => {
                                    const obj = {
                                        reportAccessControlFlag: '',
                                        userInfo: value
                                    };
                                    this.tabledata.push(obj);
                                });
                            }
                        });
                    }
                } else {
                    this.tabledata = response;
                    const ids = [];
                    this.modalInput.data?.selectedCards.map((val) => {
                        ids.push(val?.id);
                    });
                    this.bulkSharePayload['listOfReportIds'] = ids;
                }
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationsService, error);
                this.modalInput.data.widgetRef.modalService.closeModal(
                    null,
                    this.modalInput.modalId
                );
            },
            input: {},

            uniqueIdentity: Symbol()
        };

        new HitApi(
            hitApi,
            this.modalInput.data.widgetRef.httpService,
            this.modalInput.data.widgetRef.ngZone
        ).hitApi();
    }
    isReadChecked(row: any): boolean {
        if (this.modalInput.data?.cardata) {
            return row.reportAccessControlFlag === Permission.READ;
        }
    }

    isWriteChecked(row: any): boolean {
        if (this.modalInput.data?.cardata) {
            return row.reportAccessControlFlag === Permission.WRITE;
        }
    }
    isReadDisabled(row: any): boolean {
        if (this.modalInput?.data?.selectedCards) {
            if (this.disableRead) {
                return true;
            }
        }
    }

    isWriteDisabled(row: any): boolean {
        if (this.modalInput?.data?.selectedCards) {
            if (this.disableWrite) {
                return true;
            }
        }
    }
    handlePermission(event: Event, permission, rowData) {
        const checkbox = event.target as HTMLInputElement;
        if (this.modalInput.data?.cardata) {
            if (checkbox.checked) {
                rowData.reportAccessControlFlag = permission;
                if (!this.selectedRows.includes(rowData)) {
                    this.selectedRows.push(rowData);
                }
            } else if (rowData.reportAccessControlFlag === permission) {
                rowData.reportAccessControlFlag = null;
                const index = this.selectedRows.indexOf(rowData);
                this.selectedRows.splice(index, 1);
            }
        } else {
            if (checkbox.checked && permission === Permission.READ) {
                const row = {
                    reportAccessControlFlag: Permission.READ,
                    userInfo: rowData
                };
                this.reportShareModelList.push(row);
                this.bulkSharePayload['reportShareModelList'] =
                    this.reportShareModelList;
                this.disableWrite = true;
            } else if (!checkbox.checked && permission === Permission.READ) {
                const row = {
                    reportAccessControlFlag: Permission.READ,
                    userInfo: rowData
                };
                const index =
                    this.bulkSharePayload['reportShareModelList'].indexOf(row);
                this.bulkSharePayload['reportShareModelList'].splice(index, 1);
                this.disableWrite = true;
                if (this.reportShareModelList.length < 1) {
                    this.disableWrite = false;
                }
            } else if (!checkbox.checked && permission === Permission.WRITE) {
                const row = {
                    reportAccessControlFlag: Permission.WRITE,
                    userInfo: rowData
                };
                const index =
                    this.bulkSharePayload['reportShareModelList'].indexOf(row);
                this.bulkSharePayload['reportShareModelList'].splice(index, 1);
                this.disableRead = true;
                if (this.reportShareModelList.length < 1) {
                    this.disableRead = false;
                }
            } else if (checkbox.checked && permission === Permission.WRITE) {
                const row = {
                    reportAccessControlFlag: Permission.WRITE,
                    userInfo: rowData
                };
                this.reportShareModelList.push(row);
                this.bulkSharePayload['reportShareModelList'] =
                    this.reportShareModelList;
                this.disableRead = true;
            } else if (!checkbox.checked && permission === Permission.READ) {
                if (this.reportShareModelList.length < 1) {
                    this.disableWrite = false;
                }
            } else if (!checkbox.checked && permission === Permission.WRITE) {
                if (this.reportShareModelList.length < 1) {
                    this.disableRead = false;
                }
            }
        }
    }
    handleSearch(event: Event, searching) {
        this.searching = searching;
        const target = event.target as HTMLInputElement;
        const searchString = target.value.toLowerCase();
        if (!target.value) {
            // If input is empty, revert to the original data
            this.searching = false;
            this.copyData = [...this.tabledata];
            return;
        }
        if (!this.modalInput.data?.cardata) {
            this.copyData = this.tabledata.filter((val) =>
                val.firstName.toLowerCase().includes(searchString)
            );
        } else {
            this.copyData = this.tabledata.filter((val) =>
                val?.userInfo?.firstName.toLowerCase().includes(searchString)
            );
        }
    }
}
enum Permission {
    READ = 'READ',
    WRITE = 'WRITE',
}
